<template>
  <div>
    <h1>
      Totaal: {{ totalPrice }} Euro -
      <span>Aantal drankjes: {{ totalNumber }}</span>
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    totalPrice: Number,
    totalNumber: Number
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.product-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
