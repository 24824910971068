<template>
  <div class="product-item">
    <div>
      <div
        v-if="!disableActions"
        class="delete-btn"
        @click="$emit('removeOneFromOrder', data.id)"
      >
        -
        <!-- {{ isSending ? '...' : '-' }} -->
      </div>

      <div
        v-if="!disableActions"
        class="delete-btn"
        @click="$emit('addOneToOrder', data.id)"
      >
        +
        <!-- {{ isSending ? '...' : '+' }} -->
      </div>
    </div>
    <div class="order__content">
      {{ data.order }} - {{ data.name }} | {{ data.price }} |
      {{ currentTotal }} |
      {{ currentTotal * data.price }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    data: Object,
    index: Number,
    disableActions: Boolean
  },
  computed: {
    ...mapState('order', ['orderPerProduct']),
    currentTotal() {
      if (this.orderPerProduct[this.data.id]) {
        return this.orderPerProduct[this.data.id]
      } else {
        return 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.product-item {
  &.product-row {
    justify-content: flex-start;
  }
  display: flex;

  .order__content {
    padding-left: 1rem;
  }
  width: 100%;

  .product-link {
    color: $vue-color;
  }

  .delete-btn {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid;
    display: inline-block;
    border-radius: 3px;
    margin-left: 10px;
    color: $danger-color;
    border-color: $danger-color;
  }
}
</style>
