<template>
  <div class="page-wrapper">
    <div style="">
      <order-total
        :total-price="orderTotalPrice"
        :total-number="orderTotalNumber"
      ></order-total>

      <div class="order__extraInput">
        Tafelnummer:
        <input
          class="product-name-input"
          :value="tableNumber"
          @input="setTableNumber($event.target.value)"
        />
      </div>
      <v-btn @click="finishOrder">Plaats bestelling</v-btn>
    </div>

    <order-product-list class="product-list"></order-product-list>

    <!-- <add-product v-if="networkOnLine"></add-product> -->
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import OrderProductList from '@/components/OrderProductList'
import OrderTotal from '@/components/OrderTotal'

export default {
  components: { OrderProductList, OrderTotal },
  computed: {
    ...mapState('products', ['products']),
    ...mapState('order', [
      'orderPerProduct',
      'orderTotalPrice',
      'orderTotalNumber',
      'tableNumber',
      'confirmDialogOpen'
    ]),
    ...mapState('app', ['networkOnLine'])
  },
  methods: {
    ...mapMutations('order', ['setTableNumber']),
    ...mapActions('order', ['finishOrder', 'showConfirmDialog'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.products-page-title {
  text-align: center;
  margin-bottom: 60px;
}

.product-list {
  margin: 20px 0;
}

.page-wrapper > div {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .order__extraInput {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .page-wrapper > div {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
}
</style>
