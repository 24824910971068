<template>
  <div>
    <p v-if="products === null" class="infos-label">Loading...</p>
    <p v-if="products && !products.length" class="infos-label">
      You don't have any product yet
    </p>
    <order-product-item
      v-for="(product, index) in products"
      :key="product.id"
      class="product-row"
      :index="index"
      :disable-actions="!networkOnLine"
      :data="product"
      @addOneToOrder="addOneToOrder"
      @removeOneFromOrder="removeOneFromOrder"
    ></order-product-item>
  </div>
</template>

<script>
import OrderProductItem from '@/components/OrderProductItem'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: { OrderProductItem },
  computed: {
    ...mapGetters('order', ['isSendingOfOrderPending']),
    ...mapState('products', ['products']),
    ...mapState('app', ['networkOnLine'])
  },
  methods: mapActions('order', ['addOneToOrder', 'removeOneFromOrder'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.infos-label {
  text-align: center;
}

.product-row {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .product-list {
    // background-color: red;
    flex-flow: column;
    .product-row {
      width: 100%;
    }
  }
}
</style>
